import React, { useEffect, useRef } from "react";

/* Import UI components from react-bootstrap library */
import { Container, Row, Col, Button } from "react-bootstrap";

/* Import UI animations from gsap library */
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { FaWpforms } from "react-icons/fa";

/* Import 'grid2.png' image for the section of HomeRegister */
import gridImage from "../../images/grid2.png";

/* Import HomeRegister.css for styling the section of HomeRegister */
import "./HomeRegister.css";

gsap.registerPlugin(ScrollTrigger);

function HomeRegister() {
  const registerSectionRef = useRef(null);
  const headingRef = useRef(null);
  const paragraphRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const registerSection = registerSectionRef.current;
    const heading = headingRef.current;
    const paragraph = paragraphRef.current;
    const button = buttonRef.current;

    gsap.fromTo(
      heading,
      { opacity: 0, y: -50 },
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: registerSection,
          start: "top 80%",
        },
      }
    );

    gsap.fromTo(
      paragraph,
      { opacity: 0, y: -30 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        delay: 0.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: registerSection,
          start: "top 80%",
        },
      }
    );

    gsap.fromTo(
      button,
      { opacity: 0, scale: 0.8 },
      {
        opacity: 1,
        scale: 1,
        duration: 1,
        delay: 0.4,
        ease: "power3.out",
        scrollTrigger: {
          trigger: registerSection,
          start: "top 80%",
        },
      }
    );
  }, []);

  return (
    <div
      className="home-register-section bg-light"
      ref={registerSectionRef}
      style={{ backgroundImage: `url(${gridImage})` }}
    >
      {/* Abstract background for the section */}
      <div className="home-register-background"></div>

      <Container>
        <Row className="justify-content-center text-center">
          <Col xs={12} md={8}>
            <h1 className="pb-4" ref={headingRef}>
              Είσαι δικηγόρος ?
            </h1>
            <p className="mb-4 pb-4 hero-p" ref={paragraphRef}>
              Συμπλήρωσε την φόρμα αίτησης για να εγγραφείς ως δικηγόρος στην
              πλατφόρμα του upLawyer, ενισχύοντας την προβολή σου στο διαδίκτυο.
            </p>
            <Button
              className="btn btn-outline-register"
              size="md"
              ref={buttonRef}
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/1h3gxzQAawHPBa2m5Bkc-YlWx2UrXNcWwCSpAtOZGRgE",
                  "_blank"
                )
              }
            >
              Φόρμα Αίτησης <FaWpforms className="ms-2" />
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeRegister;
