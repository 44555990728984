import { gsap } from "gsap";

export const animateLawyerDetailPage = (lawyer, refs) => {
  const { bioRef, eduRef, langRef, blogRef } = refs;

  const isMobile = window.innerWidth < 1068;

  if (lawyer && !isMobile) {
    const timeline = gsap.timeline({ defaults: { ease: "power2.out" } });

    timeline.fromTo(
      bioRef.current,
      { opacity: 0, y: "10%" },
      { opacity: 1, y: "0%", duration: 1 },
      "-=0.8"
    );

    timeline.fromTo(
      eduRef.current,
      { opacity: 0, y: "10%" },
      { opacity: 1, y: "0%", duration: 1.2 },
      "-=0.8"
    );

    timeline.fromTo(
      langRef.current,
      { opacity: 0, y: "10%" },
      { opacity: 1, y: "0%", duration: 1.2 },
      "-=0.8"
    );

    timeline.fromTo(
      blogRef.current,
      { opacity: 0, y: "10%" },
      { opacity: 1, y: "0%", duration: 1.2 },
      "-=0.8"
    );
  }
};
