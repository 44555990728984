import React from "react";

import { CiMapPin } from "react-icons/ci";
import { IoMdCall } from "react-icons/io";

const LawyerAddress = ({ lawyer }) => (
  <div>
    <div className="d-flex align-items-center mb-4">
      <div className="icon-background me-4">
        <CiMapPin className="icon-style" />
      </div>
      <div>
        <h6 className="education-title mb-2">Διεύθυνση</h6>
        <p className="education-subtitle mb-0">
          {" "}
          {lawyer.lawAddress[0].lawStreet}, {lawyer.lawAddress[0].lawCity},{" "}
          {lawyer.lawAddress[0].lawZipCode}
        </p>
      </div>
    </div>
    <div className="d-flex align-items-center mb-2">
      <div className="icon-background me-4">
        <IoMdCall className="icon-style" />
      </div>
      <div>
        <h6 className="education-title mb-2">Τηλέφωνο</h6>
        <p className="education-subtitle mb-0">
          {" "}
          {lawyer.lawContact[0].lawPhone}
        </p>
      </div>
    </div>
  </div>
);
export default LawyerAddress;
