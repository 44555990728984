import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

const UserContact = ({ lawyerInfo, setLawyerInfo }) => {
  const [lawContact, setLawContact] = useState(lawyerInfo.lawContact || []);
  const [phoneErrors, setPhoneErrors] = useState([]); // Track validation errors for phone numbers
  const [canSave, setCanSave] = useState(false); // Track whether the save button should be enabled

  // Handle adding a new phone input
  const handleAddPhone = () => {
    if (lawContact.length < 3) {
      setLawContact([...lawContact, { lawPhone: "", lawPhoneType: "office_phone" }]);
      setPhoneErrors([...phoneErrors, true]); // Initialize phoneErrors for the new phone field as invalid
    }
  };

  // Handle removing the phone input
  const handleRemovePhone = (index) => {
    if (lawContact.length > 1) {
      const updatedContacts = lawContact.filter((_, i) => i !== index);
      const updatedErrors = phoneErrors.filter((_, i) => i !== index);
      setLawContact(updatedContacts);
      setPhoneErrors(updatedErrors); // Remove the corresponding error tracking
    }
  };

  // Validate phone number (should contain exactly 10 digits)
  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  // Handle input change for phone number
  const handlePhoneChange = (index, value) => {
    // Allow only digits (while typing)
    if (/^\d*$/.test(value)) {
      const updatedContacts = [...lawContact];
      updatedContacts[index].lawPhone = value;
      setLawContact(updatedContacts);
    }
  };

  // Validate phone number when user leaves the input field (onBlur)
  const handlePhoneBlur = (index) => {
    const updatedErrors = [...phoneErrors];
    updatedErrors[index] = !validatePhone(lawContact[index].lawPhone); // Mark as error if phone is not valid
    setPhoneErrors(updatedErrors);
  };

  // Handle input change for phone type
  const handlePhoneTypeChange = (index, value) => {
    const updatedContacts = [...lawContact];
    updatedContacts[index].lawPhoneType = value;
    setLawContact(updatedContacts);
  };

  // Check if there is at least one valid phone number and no invalid inputs
  useEffect(() => {
    // Ensure at least one valid phone and no invalid inputs
    const hasValidPhone = lawContact.some((contact, index) => validatePhone(contact.lawPhone));
    const hasNoInvalidPhone = phoneErrors.every((error) => !error); // No invalid phone errors

    setCanSave(hasValidPhone && hasNoInvalidPhone); // Enable save only if all phones are valid or empty
  }, [lawContact, phoneErrors]);

  return (
    <Form className="bottom-border-form px-5">
      {lawContact && lawContact.length > 0 ? (
        lawContact.map((contact, index) => (
          <Row key={index} className="mb-3">
            {/* Phone Number Input with Validation */}
            <Col md={4}>
              <Form.Group controlId={`lawPhone-${index}`} className="custom-form-group">
                <Form.Label className="custom-label">Τηλέφωνο</Form.Label>
                <Form.Control
                  type="text"
                  value={contact.lawPhone}
                  onChange={(e) => handlePhoneChange(index, e.target.value)}
                  onBlur={() => handlePhoneBlur(index)} // Trigger validation when user leaves the input field
                  placeholder="Εισαγωγή τηλεφώνου"
                  isInvalid={phoneErrors[index]} // Apply Bootstrap's is-invalid class if phone is invalid
                  className="custom-input"
                />
                <Form.Control.Feedback type="invalid">
                  Το τηλέφωνο πρέπει να περιέχει ακριβώς 10 ψηφία.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            {/* Phone Type Select Input */}
            <Col md={4}>
              <Form.Group controlId={`lawPhoneType-${index}`} className="custom-form-group">
                <Form.Label className="custom-label">Τύπος</Form.Label>
                <Form.Control
                  as="select"
                  value={contact.lawPhoneType}
                  onChange={(e) => handlePhoneTypeChange(index, e.target.value)}
                  className="custom-input"
                >
                  <option value="office_phone">Σταθερό Γραφείου</option>
                  <option value="mobile_phone">Κινητό Τηλέφωνο</option>
                </Form.Control>
              </Form.Group>
            </Col>

            {/* Add/Remove Buttons */}
            <Col md={4} className="d-flex align-items-center">
              <Button
                variant="outline-primary"
                className="me-2"
                onClick={handleAddPhone}
                disabled={lawContact.length >= 3 || (index === lawContact.length - 1 && contact.lawPhone === "")}
              >
                +
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => handleRemovePhone(index)}
                disabled={lawContact.length === 1} // Disable "-" if there's only 1 phone
              >
                -
              </Button>
            </Col>
          </Row>
        ))
      ) : (
        <p>Δεν υπάρχουν στοιχεία επικοινωνίας.</p>
      )}

      {/* Save Button */}
      <Row className="mt-4">
        <Col md={3}>
          <Button variant="outline-primary" type="button" disabled={!canSave}>
            Αποθήκευση
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UserContact;
