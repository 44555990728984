import React, { useState, useRef } from "react";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import axios from "axios";

const ProfileImageUpload = ({ lawyerInfo, AUTH_TOKEN, URL, setLawyerInfo }) => {
  const [selectedProfileImage, setSelectedProfileImage] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedAsset, setUploadedAsset] = useState(null);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const timeoutRef = useRef(null);

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedProfileImage(file);
    }
  };

  const uploadProfileImage = async (file) => {
    const createAssetMutation = `
      mutation createAsset {
        createAsset(data: {}) {
          id
          url
          upload {
            status
            expiresAt
            error {
              code
              message
            }
            requestPostData {
              url
              date
              key
              signature
              algorithm
              policy
              credential
              securityToken
            }
          }
        }
      }
    `;

    try {
      const headers = {
        Authorization: `Bearer ${AUTH_TOKEN}`,
        "Content-Type": "application/json",
      };

      const createAssetResponse = await axios.post(URL, { query: createAssetMutation }, { headers });

      if (createAssetResponse.status === 200 && createAssetResponse.data && createAssetResponse.data.data) {
        const asset = createAssetResponse.data.data.createAsset;
        const uploadData = asset.upload.requestPostData;
        const formData = new FormData();

        formData.append("key", uploadData.key);
        formData.append("policy", uploadData.policy);
        formData.append("X-Amz-Algorithm", uploadData.algorithm);
        formData.append("X-Amz-Credential", uploadData.credential);
        formData.append("X-Amz-Date", uploadData.date);
        formData.append("X-Amz-Security-Token", uploadData.securityToken);
        formData.append("X-Amz-Signature", uploadData.signature);
        formData.append("file", file);

        const uploadResponse = await axios.post(uploadData.url, formData, {
          headers: {
            // Let the browser set the Content-Type automatically
          },
        });

        if (uploadResponse.status === 204) {
          setUploadedAsset(asset);
          return asset;
        } else {
          throw new Error("Failed to upload file");
        }
      } else {
        throw new Error("Failed to create asset");
      }
    } catch (error) {
      console.error("Error during upload:", error);
      if (error.response && error.response.data) {
        console.error("GraphQL error response:", error.response.data.errors);
      }
      throw error;
    }
  };

  const handleProfileImageUpload = async () => {
    if (selectedProfileImage) {
      setUploadLoading(true);
      try {
        const uploadedAsset = await uploadProfileImage(selectedProfileImage);
        setSuccessMessage("Profile image uploaded successfully.");

        const checkAssetAvailability = (asset, retries = 20, interval = 1000) => {
          let attempts = 0;
          const checkImage = () => {
            const img = new Image();
            img.onload = () => {
              setUploadedAsset(asset);
              setSuccessMessage("Profile image updated successfully.");
            };
            img.onerror = () => {
              if (attempts < retries) {
                attempts++;
                setTimeout(checkImage, interval);
              } else {
                setError("Uploaded image is not yet available. Please try again later.");
              }
            };
            img.src = asset.url;
          };
          checkImage();
        };

        checkAssetAvailability(uploadedAsset);
      } catch (error) {
        setError("Failed to upload profile image.");
      } finally {
        setUploadLoading(false);
        setSelectedProfileImage(null);
      }
    }
  };

  const handlePublishAsset = async () => {
    if (uploadedAsset) {
      const publishAssetAndUpdateLawyerMutation = `
        mutation PublishAssetAndUpdateLawyer {
          publishAsset(where: { id: "${uploadedAsset.id}" }, to: PUBLISHED) {
            id
            url
          }
          updateLawyer(
            where: { id: "${lawyerInfo.id}" }
            data: { lawProfileImage: { connect: { id: "${uploadedAsset.id}" } } }
          ) {
            id
            lawProfileImage {
              id
              url
            }
          }
          publishLawyer(where: { id: "${lawyerInfo.id}" }) {
            id
          }
        }
      `;

      try {
        const headers = {
          Authorization: `Bearer ${AUTH_TOKEN}`,
          "Content-Type": "application/json",
        };

        const response = await axios.post(
          URL,
          { query: publishAssetAndUpdateLawyerMutation },
          { headers }
        );

        if (response.status === 200 && response.data.data) {
          setSuccessMessage("Profile image updated successfully.");
          timeoutRef.current = setTimeout(() => {
            setLawyerInfo((prevLawyerInfo) => ({
              ...prevLawyerInfo,
              lawProfileImage: response.data.data.updateLawyer.lawProfileImage,
            }));
          }, 10000);

          setUploadedAsset(null);
        } else {
          setError("Failed to update profile image. Please try again.");
        }
      } catch (error) {
        console.error("Error publishing asset and updating lawyer:", error);
        setError("Failed to update profile image. Please try again.");
      }
    }
  };

  return (
    <div className="profile-image-upload">
      {error && <Alert variant="danger">{error}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}

      <Form className="mt-4">
        <h4>Update Profile Image</h4>
        <Form.Group controlId="profileImage" className="mb-3">
          <Form.Label>Profile Image</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={handleProfileImageChange}
          />
        </Form.Group>
        <Button
          variant="primary"
          onClick={handleProfileImageUpload}
          disabled={uploadLoading || !selectedProfileImage}
        >
          {uploadLoading ? <Spinner animation="border" size="sm" /> : "Upload"}
        </Button>
        {uploadedAsset && (
          <div className="mt-3">
            <Button
              variant="success"
              onClick={handlePublishAsset}
              className="ms-3"
            >
              Publish
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default ProfileImageUpload;
