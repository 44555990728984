// src/components/Home/Home.js
import React from 'react';

/* Import Helmet component from react-helmet-async library */
import { Helmet } from "react-helmet-async";

/* Import HomeHero section */
import HomeHero from './HomeHero';
/* Import HomeTestimonals section */
import HomeTestimonals from './HomeTestimonals';
/* Import HomeLawyers section */
import HomeLawyers from './HomeLawyers';
/* Import HomeAI section */
import HomePros from './HomePros';
/* Import HomeRegister section */
import HomeRegister from './HomeRegister';

function Home() {
  return (
    <>
      <Helmet>
        <title>Αρχική Σελίδα - upLawyer.gr | Βρες δικηγόρο εύκολα και απλά.</title>
      </Helmet>
      <HomeHero />
      <HomeTestimonals />
      <HomeLawyers />
      <HomePros />
      <HomeRegister />
    </>
  );
}

export default Home;
