import React from "react";
/* Import Helmet component from react-helmet-async library */
import { Helmet } from "react-helmet-async";

const LawyerDetailSEO = ({ lawyer, slugName }) => {
  const specialties = lawyer.lawSpecialty
    .map((s) => s.specialtyName)
    .join(", ");

  return (
    <Helmet>
      <title>{`${lawyer.firstName} ${lawyer.lastName} - upLawyer.gr - Βρές δικηγόρο και άλλες νομικές υπηρεσίες στην Ελλάδα.`}</title>
      <meta
        name="description"
        content={`${lawyer.firstName} ${
          lawyer.lastName
        } - Δικηγόρος με ειδίκευση σε ${specialties}.`}
      />
      <meta
        property="og:title"
        content={`${lawyer.firstName} ${lawyer.lastName} - upLawyer.gr`}
      />
      <meta
        property="og:description"
        content={`${lawyer.firstName} ${
          lawyer.lastName
        } - Δικηγόρος με ειδίκευση σε ${specialties}.`}
      />
      <meta property="og:image" content={lawyer.lawProfileImage.url} />
      <meta
        property="og:url"
        content={`https://uplawyer.gr/dikigoros/${slugName}`}
      />
    </Helmet>
  );
};

export default LawyerDetailSEO;
