import React, { useState, useEffect } from "react";
import { Form, Button, Spinner, Alert, Row, Col } from "react-bootstrap";
import axios from "axios";

const UserBio = ({ lawyerInfo, setLawyerInfo, AUTH_TOKEN, URL }) => {
  const [lawShortBio, setLawShortBio] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Populate form fields with initial data from lawyerInfo when component mounts or updates
  useEffect(() => {
    if (lawyerInfo.lawShortBio) {
      setLawShortBio(lawyerInfo.lawShortBio || "");
    }
  }, [lawyerInfo]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");
    setLoading(true);
  
    const mutation = `
      mutation {
        updateLawyer(
          where: { id: "${lawyerInfo.id}" }
          data: {
            lawShortBio: ${JSON.stringify(lawShortBio || "")}
          }
        ) {
          lawShortBio
        }
      }
    `;
  
    try {
      const headers = {
        Authorization: `Bearer ${AUTH_TOKEN}`,
        "Content-Type": "application/json",
      };
  
      const response = await axios.post(URL, { query: mutation }, { headers });
  
      if (response.status === 200 && response.data.data) {
        // Update the full lawyerInfo state with the updated bio
        setLawyerInfo((prevInfo) => ({
          ...prevInfo,
          lawShortBio: response.data.data.updateLawyer.lawShortBio,
        }));
  
        // Publish mutation to make the update live
        const publishMutation = `
          mutation {
            publishLawyer(where: { id: "${lawyerInfo.id}" }) {
              id
              publishedAt
            }
          }
        `;
  
        const publishResponse = await axios.post(URL, { query: publishMutation }, { headers });
  
        if (publishResponse.status === 200 && publishResponse.data.data) {
          setSuccessMessage("Το βιογραφικό σας ανανεώθηκε και δημοσιεύθηκε επιτυχώς.");
        }
      } else {
        setErrorMessage("Υπήρξε πρόβλημα με την ενημέρωση του βιογραφικού.");
      }
    } catch (error) {
      console.error("Error during mutation:", error);
      setErrorMessage("Υπήρξε πρόβλημα με την ενημέρωση του βιογραφικού.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Form className="bottom-border-form px-5" onSubmit={handleSubmit} noValidate>
      <h4 className="my-4 pb-4">Πληροφορίες Βιογραφικού</h4>

      {/* Display Success Message */}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}

      {/* Display Error Message */}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

      {/* Short Bio Form */}
      <Row className="mb-3 align-items-end">
        <Col md={9}>
          <Form.Group controlId="lawShortBio">
            <Form.Label>Σύντομο Βιογραφικό</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              id="lawShortBio"
              name="lawShortBio"
              value={lawShortBio}
              onChange={(e) => setLawShortBio(e.target.value)}
              placeholder="Εισαγωγή σύντομου βιογραφικού"
              required
              className="custom-textarea" // Optional custom class for further styling
            />
          </Form.Group>
        </Col>

        {/* Save Button */}
        <Col md={3} className="px-5">
          <Button variant="outline-primary-bottom" type="submit" disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : "Αποθήκευση Αλλαγών"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UserBio;
