import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Form,
  Button,
  Alert,
  Spinner,
  Modal,
  Container,
} from "react-bootstrap";
import axios from "axios";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import showdown from "showdown";

const EditArticle = () => {
  const URL =
    "https://us-east-1-shared-usea1-02.cdn.hygraph.com/content/cm076afrs03tn07w4hrdwp8vl/master";
  const AUTH_TOKEN =
    "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE3MjQ1MTkzMjYsImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN0LTEtc2hhcmVkLXVzZWExLTAyLmh5Z3JhcGguY29tL3YyL2NtMDc2YWZyczAzdG4wN3c0aHJkd3A4dmwvbWFzdGVyIiwibWFuYWdlbWVudC1uZXh0LmdyYXBoY21zLmNvbSJdLCJpc3MiOiJodHRwczovL21hbmFnZW1lbnQtdXMtZWFzdC0xLXNoYXJlZC11c2VhMS0wMi5oeWdyYXBoLmNvbS8iLCJzdWIiOiIzMThmNjIzMC1mOWU5LTRlMDEtYmE1Ny03NWNjNjMwMTFiOGYiLCJqdGkiOiJjbTA4ZWE0aTcwcXpuMDdrOWQ2M2E5cm1iIn0.yWBWyAIBEOZNEduBlI-6CgrUHnzAyij-3Bv7G_6gbPlLERdDW7usHO1AaZK8prIJ2PCTHQhAB9PQXGSmi5FRfTJpGCxXOaAwsfqrkrnBeG8i4bSi8K-BYNbDU2yAMXT-ZsZagNT_2jkyLXFEc3cgNqBtK50rWIAmcbSPxmQy8It-5SWK3q_SewYEVLcFZ7_3lN0G6k_P_u3mQvFcOsluTDGG24-TEeu6O4M0njK2gpLpBbyl8ZNZwYjEaLT7dMjXMzznc3Fo1CLW9WSZUFtMj9C2p4BLlIBioj8jZAxE-443GTSWDnCoUnh2J7ZIfHgGC8gpX6m5cmK2MMvfLXjSzFvZCqHFlbnF5CGj5-3WWmpVgZhzoO4h28cjx668mjKlRE1HwjVF8LYRLOjh1ZxjWxuMlsQd5rpLB3DrEuXTlvG_APZGOZdzZ2wwdNSe3AFMrhS-DtfT8H4GAFsb6UTamSeeiaJ3syNw0IwovP6mNsPLcIpcuzgbh-RU2OYQH83IsYL5e8N_KbX1ZLtIKHmCf40KnJGZafO9R-pUzqpoe1sAEeVt-7NATRC3Chc8ZYzQ1IUoUIF2__Wt-EDLapsaf3JwUutJFSpWI03VSy5jGBsiJvelmt-at8_cGm5_9eF6EllYwlIHJF0s7cSaQCr5ioAJeR9bcYkitGjRL62UXOA";

  const location = useLocation();
  const navigate = useNavigate();

  const { title: initialTitle, body: initialBody, id } = location.state || {};

  const [title, setTitle] = useState(initialTitle || "");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const editorRef = useRef(null);
  const quillRef = useRef(null);

  const converter = new showdown.Converter();
  const initialHTML = initialBody ? converter.makeHtml(initialBody) : "";

  useEffect(() => {
    if (!quillRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline"],
            ["link", "image"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
      });
  
      if (initialBody) {
        quillRef.current.root.innerHTML = initialHTML;
      }
    }
  }, [initialBody, initialHTML]);

  const convertDeltaToHygraphRichTextAST = (delta) => {
    console.log("Input Delta:", delta);
  
    const children = [];
    for (let i = 0; i < delta.ops.length; i++) {
      const op = delta.ops[i];
  
      if (typeof op.insert === "string") {
        // Check if the next op has a header attribute, indicating a heading
        if (op.insert.trim() && delta.ops[i + 1]?.attributes?.header) {
          children.push({
            type: "heading",
            depth: delta.ops[i + 1].attributes.header,
            children: [
              {
                type: "text", // Explicitly setting the type as "text"
                text: op.insert.trim(),
              },
            ],
          });
          i++; // Skip the next op since it's already handled
        } else {
          // Regular paragraph
          children.push({
            type: "paragraph",
            children: [
              {
                type: "text", // Explicitly setting type as "text"
                text: op.insert.trim(),
                bold: op.attributes?.bold || false,
                italic: op.attributes?.italic || false,
                underline: op.attributes?.underline || false,
              },
            ],
          });
        }
      } else if (op.insert.image) {
        // Handle image insertion
        children.push({
          type: "image",
          src: op.insert.image,
          altText: op.attributes?.alt || "Image",
        });
      }
    }
  
    console.log("Converted Rich Text AST:", { children });
    return { children };
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
  
    // Extract and log the HTML content of the editor
    const updatedHTML = quillRef.current.root.innerHTML;
    console.log("HTML Content:", updatedHTML);
  
    const updatedContent = quillRef.current.getContents();
    const richTextAST = convertDeltaToHygraphRichTextAST(updatedContent);
  
    console.log("Converted Rich Text AST:", JSON.stringify(richTextAST, null, 2));
  
    const updateMutation = `
      mutation updatePost($id: ID!, $title: String!, $body: RichTextAST!) {
        updateLawBlogPost(
          where: { id: $id }
          data: {
            lawPostTitle: $title
            lawPostBody: $body
          }
        ) {
          id
          lawPostTitle
          lawPostBody {
            markdown
          }
        }
      }
    `;
  
    const publishMutation = `
      mutation publishPost($id: ID!) {
        publishLawBlogPost(where: { id: $id }) {
          id
          publishedAt
        }
      }
    `;
  
    try {
      const headers = {
        Authorization: `Bearer ${AUTH_TOKEN}`,
        "Content-Type": "application/json",
      };
  
      const variables = {
        id: id,
        title: title,
        body: richTextAST,
      };
  
      // First, update the post
      const response = await axios.post(
        URL,
        { query: updateMutation, variables },
        { headers }
      );
      if (response.status === 200 && response.data.data) {
        // If update is successful, publish the post
        const publishResponse = await axios.post(
          URL,
          { query: publishMutation, variables: { id } },
          { headers }
        );
        if (publishResponse.status === 200 && publishResponse.data.data) {
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            navigate("/profile"); // Redirect to /profile after successful publish
          }, 2000);
        } else {
          setErrorMessage("Publishing the article encountered an issue.");
        }
      } else {
        setErrorMessage("Updating the article encountered an issue.");
      }
    } catch (error) {
      console.error("Error during mutation:", error);
      setErrorMessage(
        "Updating or publishing the article encountered an issue."
      );
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Container>
      <div className="edit-article">
        <h4 className="my-4">Επεξεργασία Τίτλου Άρθρου</h4>
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Form onSubmit={handleSave}>
          <Form.Group controlId="articleTitle">
            <Form.Label>Τίτλος</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Εισαγωγή νέου τίτλου"
            />
          </Form.Group>

          <div className="mt-4">
            <h5>Περιεχόμενο Άρθρου</h5>
            <div
              ref={editorRef}
              style={{
                border: "1px solid #ddd",
                padding: "10px",
                borderRadius: "5px",
                minHeight: "200px",
              }}
            />
          </div>

          <Button
            variant="primary"
            className="mt-3"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Αποθήκευση και Δημοσίευση"
            )}
          </Button>
        </Form>

        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Body className="text-center">
            <h5>Ενημερώθηκε Επιτυχώς!</h5>
          </Modal.Body>
        </Modal>
      </div>
    </Container>
  );
};

export default EditArticle;
