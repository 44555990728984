import React, { useState, useEffect } from 'react';
import { auth, provider } from '../apiCalls/firebaseConfig';
import { signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { FaGoogle } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';

import './Auth.css';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const successMessage = location.state?.successMessage || '';

  useEffect(() => {
    if (successMessage) {
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [successMessage, navigate, location.pathname]);

  const handleGoogleSignIn = () => {
    setLoading(true);
    signInWithPopup(auth, provider)
      .then((result) => {
        if (result.user.emailVerified) {
          setLoading(false);
          navigate('/profile');
        } else {
          setError('Please verify your email address before logging in.');
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setError('Failed to sign in with Google');
        setLoading(false);
      });
  };

  const handleEmailSignIn = (e) => {
    e.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        if (result.user.emailVerified) {
          setLoading(false);
          navigate('/profile');
        } else {
          setError('Please verify your email address before logging in.');
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setError('Failed to sign in with email and password');
        setLoading(false);
      });
  };

  return (
    <Container className="py-5 d-flex justify-content-center align-items-center login-container">
      <Row className="w-100 d-flex align-items-center justify-content-center full-height">
        <Col md={5} className="text-center mb-0 mb-md-0 left-column">
          <h1 className="lh-md">Καλώς ήρθες στο <span className="navbar-brand fw-medium">upLawyer.gr</span>.</h1>
          <p className="lh-lg pt-4">Συνδέσου στην εφαρμογή για να διαχειριστείς το προφίλ σου.</p>
        </Col>
        <Col md={7} className="d-flex flex-column justify-content-center align-items-center">
          <div className="w-100 form-container">
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleEmailSignIn}>
              <Form.Group className="form-floating pt-1 pb-2 mb-4">
                <Form.Control
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <Form.Label htmlFor="email">Email</Form.Label>
              </Form.Group>
              <Form.Group className="form-floating pt-1 pb-2 mb-4">
                <Form.Control
                  type="password"
                  id="password"
                  placeholder="Κωδικός πρόσβασης"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <Form.Label htmlFor="password">Κωδικός πρόσβασης</Form.Label>
              </Form.Group>
              <Button disabled={loading} className="w-100" type="submit" variant="outline-primary">
                Είσοδος
              </Button>
            </Form>
            
            <Button
              className="w-100 mt-3"
              onClick={handleGoogleSignIn}
              disabled={loading}
              variant="outline-primary"
            >
              <FaGoogle /> Sign in with Google
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
