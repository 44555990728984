import React from "react";

/* Import UI components from react-bootstrap library */
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

/* Import icons from react-icons/fa library */
import { FaGlobe, FaLinkedin, FaFacebook } from "react-icons/fa";

/* Import icons from react-icons/ai library */
import { AiOutlineSafetyCertificate } from "react-icons/ai";

const gradeMapping = {
  dikigoros_par_efetais: "Δικηγόρος παρ΄ Εφέταις",
  dikigoros_par_areio_pago: "Δικηγόρος παρ' Αρείω Πάγω",
  dikigoros_para_protodikais: "Δικηγόρος παρά Πρωτοδίκας",
};

const UserIntro = ({ lawyerInfo }) => {
  
  return (

    <div
      className={'ld-intro d-flex align-items-center justify-content-center position-relative'}
    >
      <Row className="w-100">
        <Col>
          {/* Profile Image */}
          {lawyerInfo.lawProfileImage && lawyerInfo.lawProfileImage.url && (
            <div className="ld-image-container">
              <img
                src={lawyerInfo.lawProfileImage.url}
                alt={`${lawyerInfo.firstName} ${lawyerInfo.lastName}`}
                className="ld-image-profile"
              />
            </div>
          )}

          {/* Display lawyer's full name */}
          <h1 className="lawyer-h1 text-center">
            {lawyerInfo.firstName} {lawyerInfo.lastName}
          </h1>

          {/* Display lawyer's association */}
          {lawyerInfo.lawAssociation && (
            <h2 className="lawyer-h2 text-center mt-3">
              {lawyerInfo.lawAssociation.associationName || "Κανένας δικηγορικός σύλλογος"}
            </h2>
          )}

          {/* Display lawyer's mediation */}
          {lawyerInfo.lawMediation && (
            <div className="ld-mediation">
              <AiOutlineSafetyCertificate className="mediation-icon" size={16} />{" "}
              Διαμεσολαβητής
            </div>
          )}

          {/* Display lawyer's grade */}
          <div className="pt-5">
            {lawyerInfo.lawGrade && (
              <div className="ld-grade">
                {gradeMapping[lawyerInfo.lawGrade]}
              </div>
            )}

            <div className="ld-social-icons">
              {lawyerInfo.website && (
                <a href={lawyerInfo.website} target="_blank" rel="noopener noreferrer">
                  <FaGlobe className="social-icon" />
                </a>
              )}
              {lawyerInfo.linkedin && (
                <a href={lawyerInfo.linkedin} target="_blank" rel="noopener noreferrer">
                  <FaLinkedin className="social-icon" />
                </a>
              )}
              {lawyerInfo.facebook && (
                <a href={lawyerInfo.facebook} target="_blank" rel="noopener noreferrer">
                  <FaFacebook className="social-icon" />
                </a>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UserIntro;
