import React from "react";

/* Import UI components from react-bootstrap library */
import { Card } from "react-bootstrap";

import showdown from 'showdown';

import { FaArrowRight } from 'react-icons/fa';

const converter = new showdown.Converter({
  noHeaderId: true,
  simplifiedAutoLink: true,
});

const BlogPost = ({ post }) => {
  const { lawPostTitle, lawPostDate, lawPostBody, lawyer } = post;
  const blogPostAuthor = `${lawyer.firstName} ${lawyer.lastName}`;
  const blogPostExcerptRaw = lawPostBody.markdown.slice(0, 500);

  const blogPostExcerpt = converter.makeHtml(blogPostExcerptRaw).replace(/<[^>]+>/g, '');

  return (
    <Card className="blog-card">
      <Card.Body>
        <div className="d-flex justify-content-center text-center py-3 blog-meta">
          <span className="me-1">{new Date(lawPostDate).toLocaleDateString()}</span>
          <span>| {blogPostAuthor}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center pb-3">
          <span className="text-center blog-title">
            {lawPostTitle}
          </span>
        </div>
        <div className="mt-3 blog-excerpt text-justify">
          <Card.Text>
            {blogPostExcerpt}        
            <span className="ms-4 blog-read-more">
              Πλήρες άρθρο <FaArrowRight />
            </span>
          </Card.Text>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BlogPost;
