import React from "react";

/* Import UI components from react-bootstrap library */
import { Container, Row } from "react-bootstrap";

/* Import Helmet component from react-helmet-async library */
import { Helmet } from "react-helmet-async";

/* Import .css to style the JSX template */
import "./Policy.css";

import termsConditionsContent from "./policyTermsContent.json";

const SectionHeader = ({ children, level = 3 }) => {
  const Tag = `h${level}`;
  return <Tag className="py-3 fw-medium">{children}</Tag>;
};

const Paragraph = ({ children }) => <p>{children}</p>;

function PolicyPrivacy() {
  return (
    <>
      <Helmet>
        <title>{termsConditionsContent.title} - upLawyer.gr | Βρες δικηγόρο εύκολα και απλά.</title>
      </Helmet>

      <Container className="my-3 pt-3">
        <Row className="text-center text-md-start">
          <h1 className="my-2">{termsConditionsContent.title}</h1>
          <h5 className="mb-4 mt-3">{termsConditionsContent.subtitle}</h5>
        </Row>
      </Container>
      <div className="px-4">
      <Container className="my-3">
        <Row className="policy-p">
          {termsConditionsContent.sections.map((section, index) => (
            <section key={index}>
              <SectionHeader level={3}>{section.header}</SectionHeader>
              {section.content && <Paragraph>{section.content}</Paragraph>}

              {section.list && (
                <ul>
                  {section.list.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              )}

              {section.subsections && section.subsections.map((subsec, subIndex) => (
                <React.Fragment key={subIndex}>
                  <strong>{subsec.subheader}</strong>
                  <Paragraph>{subsec.content}</Paragraph>
                </React.Fragment>
              ))}

              {section.rights && (
                <ul>
                  {section.rights.map((right, idx) => (
                    <li key={idx}>
                      <strong>{right.title}:</strong> {right.description}
                    </li>
                  ))}
                </ul>
              )}

              {section.subcontent && <Paragraph>{section.subcontent}</Paragraph>}
            </section>
          ))}
        </Row>
      </Container>
      </div>
    </>
  );
}

export default PolicyPrivacy;
