import React from "react";

/* Import UI components from react-bootstrap library */
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

/* Import icons from react-icons/fa library */
import { FaGlobe, FaLinkedin, FaFacebook } from "react-icons/fa";

/* Import icons from react-icons/ai library */
import { AiOutlineSafetyCertificate } from "react-icons/ai";

const gradeMapping = {
  dikigoros_par_efetais: "Δικηγόρος παρ΄ Εφέταις",
  dikigoros_par_areio_pago: "Δικηγόρος παρ' Αρείω Πάγω",
  dikigoros_para_protodikais: "Δικηγόρος παρά Πρωτοδίκας",
};

const LawyerIntro = ({ lawyer }) => (
  <div
    className={'ld-intro d-flex align-items-center justify-content-center position-relative'}
  >
    <Row className="w-100">
      <Col>
        {/* Profile Image */}
        {lawyer.lawProfileImage && lawyer.lawProfileImage.url && (
          <div className="ld-image-container">
            <img
              src={lawyer.lawProfileImage.url}
              alt={`${lawyer.firstName} ${lawyer.lastName}`}
              className="ld-image-profile"
            />
          </div>
        )}

        {/* Display lawyer's full name */}
        <h1 className="lawyer-h1 text-center">
          {lawyer.firstName} {lawyer.lastName}
        </h1>

        {/* Display lawyer's association */}
        {lawyer.lawAssociation && (
            <h2 className="lawyer-h2 text-center mt-3">
            {lawyer.lawAssociation.associationName || "Κανένας δικηγορικός σ"}
            </h2>
        )}

        {/* Display lawyer's mediation */}
        {lawyer.lawMediation && (
          <div className="ld-mediation">
            <AiOutlineSafetyCertificate className="mediation-icon" size={16} />{" "}
            Διαμεσολαβητής
          </div>
        )}

        {/* Display lawyer's grade */}
        <div className="pt-5">

          {lawyer.lawGrade && (
            <div className="ld-grade">
              {gradeMapping[lawyer.lawGrade]}
            </div>
          )}

          {/* Display lawyer's social media & website */}
          <div className="ld-social-icons">
            <a href={lawyer.website} target="_blank" rel="noopener noreferrer">
              <FaGlobe className="social-icon" />
            </a>
            <a href={lawyer.linkedin} target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="social-icon" />
            </a>
            <a href={lawyer.facebook} target="_blank" rel="noopener noreferrer">
              <FaFacebook className="social-icon" />
            </a>
          </div>

        </div>
      </Col>
    </Row>
  </div>
);

export default LawyerIntro;
