import React, { useState, useRef, useEffect } from "react";

/* Import UI components from Bootstrap UI */
import { Container, Row, Col, Button, Form } from "react-bootstrap";

import { gsap } from "gsap";

/* Import icons from from react-icons library  */
import { FaPaperPlane } from "react-icons/fa";

import OpenAILogo from "../../images/openAI-logo.png";

/* Import custom .css file for the JSX template */
import "./AIAssistant.css";

const OpenAIPage = () => {
  const [response, setResponse] = useState("");
  const [inputText, setInputText] = useState("");

  const countWords = (text) => {
    return text.trim().split(/\s+/).length;
  };

  const getLawyerSpecialtyFromOpenAI = async (text) => {
    try {
      const result = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer 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`,
        },
        body: JSON.stringify({
          model: "gpt-4o-mini",
          messages: [
            {
              role: "system",
              content: `
                  You are a legal assistant that categorizes legal cases into different specialties of law based on the case description. 
                  Here are the specialties and associated cases:
                  
                  - Αστικό Δίκαιο: Οικογενειακές υποθέσεις (επιμέλεια τέκνων, διατροφή, συμμετοχή στα αποκτήματα, προσβολή πατρότητας, υιοθεσία, δικαστική συμπαράσταση), αστική αποζημίωση (τροχαία ατυχήματα, ευθύνη από σύμβαση, αδικοπραξία), Ακίνητα (μίσθωση, πώληση, άδειες, υποθήκη, κυριότητα), Συμβάσεις, ιδιωτικές συμφωνίες, Κληρονομιά, Διαθήκη, Χρέη (Νόμος Κατσέλη κ.λ.π), προστασία καταναλωτή, αποζημίωση για βλάβη υγείας ή σώματος, δυσφημιστικές διαδόσεις. Επίσης, στο Αστικό Δίκαιο συμπεριλαμβάνονται οι αποζημιώσεις για τα ποινικά αδικήματα.
                  
                  - Εμπορικό Δίκαιο: Εταιρικές Συμβάσεις, Νομικός έλεγχος εταιρειών, Σήματα, Ευρεσιτεχνίες, Πνευματική ιδιοκτησία, Εξαγορές και συγχωνεύσεις, Αναδιάρθρωση και διάσπαση, Λύση και εκκαθάριση, Πτώχευση-Συνδιαλλαγή-Εξυγίανση-Ειδική Εκκαθάριση επιχειρήσεων, Αξιόγραφα (επιταγές, συναλλαγματικές), Ασφαλιστικό δίκαιο (ιδιωτική ασφάλιση), Ναυτικό Δίκαιο, Αεροπορικό Δίκαιο.
          
                  - Εργατικό Δίκαιο: Ερμηνεία, καταγγελία σύμβασης εργασίας και καταβολή αποζημιώσεων, Αξιώσεις δεδουλευμένων, επιδομάτων, αδειών και μισθών υπερημερίας, Εργατικά ατυχήματα, Απονομή Συντάξεων, Αναθεώρηση όρων εργασίας, Συμβάσεις παροχής ανεξαρτήτων υπηρεσιών, συμβάσεις έργου και συμβάσεις εντολής, Αποζημίωση διοικητικών στελεχών, Ομαδικές απολύσεις, Ατομικό και Συλλογικό Εργατικό δίκαιο.
          
                  - Δίκαιο Ακινήτων: Πωλήσεις, Μισθώσεις, Απαλλοτριώσεις, Προσύμφωνα – Συμβόλαια Αγοραπωλησίας Ακινήτων, Έλεγχοι Τίτλων Ιδιοκτησίας ακινήτων, Εθνικό Κτηματολόγιο.
          
                  - Ποινικό Δίκαιο: Ποινικά αδικήματα (κλοπή, ληστεία, φόνος, δολοφονία, απάτη, σεξουαλική επίθεση, βία, σωματική βλάβη, ναρκωτικά, όπλα, οπλοφορία, ανθρωποκτονία, βιασμός, υπεξαίρεση, ηχορρύπανση, ζωοκλοπή, παρενόχληση), Δικαστική εκπροσώπηση σε ποινικά δικαστήρια, Εισαγγελέας, Ποινική υπεράσπιση, Κατηγορίες και απολογίες, Εγγυήσεις, Αποφυλακίσεις, Κατάχρηση εξουσίας, Παράνομες δραστηριότητες, Εγκλήματα κυβερνοχώρου, Εμπρησμός, Βία και κακοποίηση, σωματεμπορία, Εμπορία ανθρώπων, Διεθνές ποινικό δίκαιο, Έκδοση και ανάκριση, Αδικήματα ανηλίκων, αυτόφωρο, βία κατά υπαλλήλων, αστυνομικών, εξύβριση, μυνητήρια αναφορά, μήνυση, Πλημμέλημα, κακούργημα.
          
                  - Δίκαιο Αναγκαστικής Εκτέλεσης: Διαταγή πληρωμής, αναστολές, ανακοπές, Επιβολή κατάσχεσης, Είσπραξη απαιτήσεων – Ρύθμιση οφειλών.
          
                  - Διοικητικό Δίκαιο: Ιεραρχικές προσφυγές έναντι φορέων δημόσιας διοίκησης, Αγωγές, προσφυγές, ανακοπές, Διεκδίκηση μισθολογικών απαιτήσεων, βαθμολογικής εξέλιξης κ.λ.π., Αιτήσεις ακυρώσεως, Τροποποίηση ρυμοτομικού σχεδίου, Άρση απαλλοτρίωσης.
          
                  Based on the description, respond only with the exact name of the specialty, such as "Ποινικό Δίκαιο" or "Εμπορικό Δίκαιο". Do not add any extra text or explanations. If the description does not match a specific specialty, respond with "General". If the description is not related to any of these cases, respond with "Not a legal case".
                `,
            },
            { role: "user", content: `Case description: "${text}"` },
          ],
          max_tokens: 50,
          temperature: 0.0,
        }),
      });

      if (!result.ok) {
        throw new Error(`Error: ${result.status} ${result.statusText}`);
      }

      const data = await result.json();
      const content = data.choices[0].message.content.trim();

      return content;
    } catch (error) {
      console.error(
        "Error determining legal specialty with OpenAI API:",
        error
      );
      return "Error fetching response. Please try again.";
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setResponse("");

    if (countWords(inputText) > 100) {
      setResponse(
        "Το κείμενό σας ξεπερνά το όριο των 100 λέξεων. Παρακαλώ περιγράψτε την υπόθεση σας σε λιγότερες λέξεις."
      );
      return;
    }

    const specialtyResponse = await getLawyerSpecialtyFromOpenAI(inputText);

    if (specialtyResponse === "Not a legal case") {
      setResponse(
        "Μπορώ να απαντήσω μόνο σε ερωτήσεις σχετικές με νομικά ζητήματα."
      );
    } else if (specialtyResponse === "General") {
      setResponse(
        "Η υπόθεση σου δεν παρουσιάζει κάποια εξειδίκευση. Μπορείς να απευθυνθείς σε έναν δικηγόρο για να σε καθοδηγήσει."
      );
    } else if (
      specialtyResponse === "Error fetching response. Please try again."
    ) {
      setResponse(specialtyResponse);
    } else {
      const formattedSpecialty = specialtyResponse.split(" ")[0];

      if (formattedSpecialty === "Ποινικό") {
        setResponse(
          `Η υπόθεση σου μπορεί να χειριστεί από δικηγόρο με ειδίκευση στο <a href="/eidikotita/poiniko-dikaio">Ποινικό Δίκαιο</a>.`
        );
      } else {
        setResponse(
          `Η υπόθεση σου μπορεί να χειριστεί από δικηγόρο με ειδίκευση στο "${formattedSpecialty} Δίκαιο".`
        );
      }
    }
  };

  const svgRef = useRef(null);
  const pathRef = useRef(null);

  useEffect(() => {
    gsap.to(pathRef.current, {
      duration: 4,
      repeat: -1,
      yoyo: true,
      ease: "power1.inOut",
      attr: {
        d: "M0,160L48,149.3C96,139,192,117,288,112C384,107,480,117,576,117.3C672,117,768,107,864,112C960,117,1056,139,1152,149.3C1248,160,1344,160,1392,160L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z",
      },
    });
  }, []);

  return (
    <div className="page-wrapper position-relative">
      {/* SVG Shape at the bottom */}
      <svg
        ref={svgRef}
        className="bottom-curve"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1600 320"
      >
        <path
          ref={pathRef}
          fill="#ffffff"
          fillOpacity="1"
          d="M0,224L48,192C96,160,192,96,288,101.3C384,107,480,181,576,202.7C672,224,768,192,864,186.7C960,181,1056,203,1152,213.3C1248,224,1344,224,1392,224L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>

      <Container
        fluid
        className="d-flex flex-column justify-content-center align-items-center min-vh-100 px-5"
      >
        <Row className="w-100">
          {/* Column for the heading */}
          <Col xs={12} md={4} className="mx-auto pt-2">
            <div className="d-flex flex-column justify-content-center mb-5">
              <h1 className="pb-2 text-center text-md-start"><span className="navbar-brand">AI</span> βοηθός</h1>
              <p className="py-2 text-center text-md-start hero-p">
                Με τον{" "}
                <span className="navbar-brand fw-medium">ΑΙ βοηθό</span>{" "}
                μπορείς να αναζητήσεις την ειδικότητα δικηγόρου που ταιριάζει
                στην υπόθεσή σου με την υποστήριξη της Τεχνητής Νοημοσύνης.
              </p>
            </div>
          </Col>

          <Col xs={12} md={7} className="mx-auto pt-1 ms-3">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="inputAI" className="position-relative">
                <Form.Control
                  as="textarea"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  placeholder="Περιέγραψε την υπόθεσή σου (μέχρι 1000 λέξεις)..."
                  rows={8}
                  style={{
                    resize: "none",
                    paddingRight: "3rem",
                    paddingBottom: "2.5rem",
                    paddingLeft: "3rem",
                  }}
                />
                <Button
                  type="submit"
                  variant="outline-dark"
                  className="position-absolute"
                  style={{ right: "15px", bottom: "15px" }}
                >
                  <FaPaperPlane />
                </Button>

                <img
                  src={OpenAILogo}
                  alt="OpenAI Logo"
                  className="position-absolute openai-logo"
                  style={{ left: "18px", bottom: "18px", height: "18px" }}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>

        {/* Response Section */}
        {response && (
          <Row className="w-100">
            <Col
              xs={12}
              md={8}
              lg={6}
              className="mx-auto mt-4 p-3 ai-results"
            >
              <p
                className="text-center text-response"
                dangerouslySetInnerHTML={{ __html: response }}
              ></p>
              <p className="text-center ai-disclaimer">
                Αυτή η πρόταση δημιουργήθηκε με βάση το εργαλείο τεχνητής
                νοημοσύνης της OpenAI και δεν υποκαθιστά την καθοδήγηση από έναν
                δικηγόρο.
              </p>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default OpenAIPage;
