import React from "react";

/* Import UI components from react-bootstrap library */
import Container from "react-bootstrap/Container";

const LoadingSpinner = () => (
  <Container className="my-4">
    <h2>Φορτώνει...</h2>
  </Container>
);

export default LoadingSpinner;