import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaGlobe, FaLinkedin, FaFacebook } from "react-icons/fa";
import "./LawyerIntro.css";

const gradeMapping = {
  dikigoros_par_efetais: "Δικηγόρος παρ΄ Εφέταις",
  dikigoros_par_areio_pago: "Δικηγόρος παρ' Αρείω Πάγω",
  dikigoros_para_protodikais: "Δικηγόρος παρά Πρωτοδίκας",
};

const LawyerIntroMob = ({ lawyer }) => (
  <div className={`ld-intro-mb pt-1`}>
    {/* First row: Profile Image */}

    {/* Second row: Full name and association */}
    <Row className="w-100 mt-4 text-center">
      <Col xs={12} className="mb-4">
        {lawyer.lawGrade && (
          <div className="ld-grade-mb">{gradeMapping[lawyer.lawGrade]}</div>
        )}
      </Col>
      <Col xs={12} className="text-center">
        <h1 className="lawyer-h1">
          {lawyer.firstName} {lawyer.lastName}
        </h1>
      </Col>
      <Col xs={12}>
        {lawyer.lawAssociation && (
          <h2 className="lawyer-h2 mt-3">
            {lawyer.lawAssociation.associationName ||
              "Κανένας δικηγορικός σύλλογος"}
          </h2>
        )}
      </Col>
    </Row>

    {/* Fourth row: Mediation on the left, social media on the right */}
    <Row className="w-100 mt-4">
      <Col xs={6} className="text-right">
        <div className="lawyer-social-icons d-flex justify-content-end">
          {lawyer.website && (
            <a href={lawyer.website} target="_blank" rel="noopener noreferrer">
              <FaGlobe className="social-icon" />
            </a>
          )}
          {lawyer.linkedin && (
            <a href={lawyer.linkedin} target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="social-icon" />
            </a>
          )}
          {lawyer.facebook && (
            <a href={lawyer.facebook} target="_blank" rel="noopener noreferrer">
              <FaFacebook className="social-icon" />
            </a>
          )}
        </div>
      </Col>
    </Row>
    <Row className="pb-5">
      {lawyer.lawProfileImage && lawyer.lawProfileImage.url && (
        <div className="ld-img-container-mb">
          <img
            src={lawyer.lawProfileImage.url}
            alt={`${lawyer.firstName} ${lawyer.lastName}`}
            className="ld-img-mb"
          />
        </div>
      )}
    </Row>
  </div>
);

export default LawyerIntroMob;
