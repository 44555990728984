import React, { useState, useEffect } from "react";
import { Form, Button, Spinner, Alert, Row, Col } from "react-bootstrap";
import axios from "axios";

const UserSocialMedia = ({ lawyerInfo, setLawyerInfo, AUTH_TOKEN, URL }) => {

  // State hooks for form inputs
  const [lawWebsite, setLawWebsite] = useState("");
  const [lawLinkedin, setLawLinkedin] = useState("");
  const [lawFacebook, setLawFacebook] = useState("");

  // State hooks for validation
  const [isWebsiteInvalid, setIsWebsiteInvalid] = useState(false);
  const [isLinkedinInvalid, setIsLinkedinInvalid] = useState(false);
  const [isFacebookInvalid, setIsFacebookInvalid] = useState(false);

  // State hooks for handling feedback
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // Populate form fields with initial data from lawyerInfo when component mounts or updates
  useEffect(() => {
    if (lawyerInfo.lawSocialMedia) {
      setLawWebsite(lawyerInfo.lawSocialMedia.lawWebsite || "");
      setLawLinkedin(lawyerInfo.lawSocialMedia.lawLinkedin || "");
      setLawFacebook(lawyerInfo.lawSocialMedia.lawFacebook || "");
    }
  }, [lawyerInfo]);

  // Helper function to return null if the value is an empty string
  const handleEmptyOrNull = (value) => {
    return value.trim() === "" ? null : value;
  };

  // Validate URLs using the browser's native validation
  const handleValidation = () => {
    // Form validation using input's validity state
    const isWebsiteValid = lawWebsite === "" || document.getElementById('lawWebsite').checkValidity();
    const isLinkedinValid = lawLinkedin === "" || document.getElementById('lawLinkedin').checkValidity();
    const isFacebookValid = lawFacebook === "" || document.getElementById('lawFacebook').checkValidity();

    setIsWebsiteInvalid(!isWebsiteValid);
    setIsLinkedinInvalid(!isLinkedinValid);
    setIsFacebookInvalid(!isFacebookValid);

    return isWebsiteValid && isLinkedinValid && isFacebookValid;
  };

  // Handler for form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage("");

    // Perform validation
    if (!handleValidation()) {
      return; // If validation fails, stop the form submission
    }

    setLoading(true);

    // Dynamically insert the social media ID
    const socialMediaId = lawyerInfo.lawSocialMedia.id;

    // Create variables with null if the input is cleared
    const updatedLawWebsite = handleEmptyOrNull(lawWebsite);
    const updatedLawLinkedin = handleEmptyOrNull(lawLinkedin);
    const updatedLawFacebook = handleEmptyOrNull(lawFacebook);

    // Mutation string with null-handling for empty inputs
    const mutation = `
      mutation {
        updateLawyer(
          where: { id: "${lawyerInfo.id}" }
          data: {
            lawSocialMedia: {
              update: {
                where: { id: "${socialMediaId}" }
                data: {
                  lawWebsite: ${updatedLawWebsite !== null ? `"${updatedLawWebsite}"` : null},
                  lawLinkedin: ${updatedLawLinkedin !== null ? `"${updatedLawLinkedin}"` : null},
                  lawFacebook: ${updatedLawFacebook !== null ? `"${updatedLawFacebook}"` : null}
                }
              }
            }
          }
        ) {
          id
          lawSocialMedia {
            id
            lawWebsite
            lawLinkedin
            lawFacebook
          }
        }
      }
    `;

    try {
      const headers = {
        Authorization: `Bearer ${AUTH_TOKEN}`,
        "Content-Type": "application/json",
      };

      // Send the update mutation using Axios
      const response = await axios.post(
        URL,
        { query: mutation },
        { headers }
      );

      // Check for successful response
      if (response.status === 200 && response.data.data) {
        setLawyerInfo(response.data.data.updateLawyer.lawSocialMedia);

        // Now trigger the publish mutation
        const publishMutation = `
          mutation {
            publishLawyer(where: { id: "${lawyerInfo.id}" }) {
              id
              publishedAt
            }
          }
        `;

        const publishResponse = await axios.post(
          URL,
          { query: publishMutation },
          { headers }
        );

        // Check if publish was successful
        if (publishResponse.status === 200 && publishResponse.data.data) {
          setSuccessMessage("Οι σύνδεσμοι των κοινωνικών δικτύων σου ανανεώθηκαν και δημοσιεύθηκαν επιτυχώς.");
          // Hide success message after 5 seconds
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
        }
      }
    } catch (error) {
      console.error("Error during mutation:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form className="bottom-border-form px-5" onSubmit={handleSubmit} noValidate>
      <h4 className="my-4 pb-4">Πληροφορίες Κοινωνικών Δικτύων</h4>

      {/* Display Success Message */}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}

      {/* Inline Social Media Form */}
      <Row className="mb-3 align-items-end">
        {/* Website Input */}
        <Col md={3}>
          <Form.Group controlId="lawWebsite">
            <Form.Label>Ιστοσελίδα</Form.Label>
            <Form.Control
              type="url"
              id="lawWebsite"
              name="lawWebsite"
              value={lawWebsite}
              onChange={(e) => setLawWebsite(e.target.value)}
              placeholder="Εισαγωγή συνδέσμου Ιστοσελίδας"
              className={isWebsiteInvalid ? "is-invalid" : ""}
              required
            />
            {/* Always render the invalid-feedback, but toggle visibility */}
            <div className={`invalid-feedback ${isWebsiteInvalid ? "d-block" : ""}`}>
              Παρακαλώ εισάγετε έναν έγκυρο σύνδεσμο Ιστοσελίδας.
            </div>
          </Form.Group>
        </Col>

        {/* LinkedIn Input */}
        <Col md={3}>
          <Form.Group controlId="lawLinkedin">
            <Form.Label>LinkedIn</Form.Label>
            <Form.Control
              type="url"
              id="lawLinkedin"
              name="lawLinkedin"
              value={lawLinkedin}
              onChange={(e) => setLawLinkedin(e.target.value)}
              placeholder="Εισαγωγή συνδέσμου LinkedIn"
              className={isLinkedinInvalid ? "is-invalid" : ""}
            />
            {/* Always render the invalid-feedback, but toggle visibility */}
            <div className={`invalid-feedback ${isLinkedinInvalid ? "d-block" : ""}`}>
              Παρακαλώ εισάγετε έναν έγκυρο σύνδεσμο LinkedIn.
            </div>
          </Form.Group>
        </Col>

        {/* Facebook Input */}
        <Col md={3}>
          <Form.Group controlId="lawFacebook">
            <Form.Label>Facebook</Form.Label>
            <Form.Control
              type="url"
              id="lawFacebook"
              name="lawFacebook"
              value={lawFacebook}
              onChange={(e) => setLawFacebook(e.target.value)}
              placeholder="Εισαγωγή συνδέσμου Facebook"
              className={isFacebookInvalid ? "is-invalid" : ""}
            />
            {/* Always render the invalid-feedback, but toggle visibility */}
            <div className={`invalid-feedback ${isFacebookInvalid ? "d-block" : ""}`}>
              Παρακαλώ εισάγετε έναν έγκυρο σύνδεσμο Facebook.
            </div>
          </Form.Group>
        </Col>

        {/* Save Button */}
        <Col md={3} className="px-5">
          <Button variant="outline-primary-bottom" type="submit" disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : "Αποθήκευση Αλλαγών"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UserSocialMedia;
