import React, { useState } from "react";
import { Row, Col, Form, Button, Alert, Spinner } from "react-bootstrap";
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";
import { auth } from "../../apiCalls/firebaseConfig";
import './UserProfile.css';

const UserPassword = () => {
  const [formValues, setFormValues] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    if (formValues.newPassword !== formValues.confirmNewPassword) {
      setError("New passwords do not match.");
      return;
    }

    if (formValues.newPassword.length < 8) {
      setError("Password should be at least 8 characters long.");
      return;
    }

    setLoading(true);
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(
      user.email,
      formValues.currentPassword
    );

    reauthenticateWithCredential(user, credential)
      .then(() => {
        updatePassword(user, formValues.newPassword)
          .then(() => {
            setSuccessMessage("Password updated successfully.");
            setLoading(false);
            setFormValues({
              currentPassword: "",
              newPassword: "",
              confirmNewPassword: "",
            });
          })
          .catch((error) => {
            console.error("Error updating password:", error);
            setError("Failed to update password. Please try again.");
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error("Error re-authenticating user:", error);
        setError("Current password is incorrect.");
        setLoading(false);
      });
  };

  return (
    <Form onSubmit={handlePasswordChange} className="bottom-border-form bottom-border-form-1 px-5">
      {error && <Alert variant="danger">{error}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}

      <h4 className="my-4 pb-4">Κωδικός Πρόσβασης</h4>
      <Row>
        <Col md={3}>
          <Form.Group controlId="currentPassword" className="mb-3">
            <Form.Label>Τρέχον συνθηματικό</Form.Label>
            <Form.Control
              type="password"
              name="currentPassword"
              placeholder="Εισαγωγή κωδικού πρόσβασης"
              value={formValues.currentPassword}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="newPassword" className="mb-3">
            <Form.Label>Νέος κωδικός πρόσβασης</Form.Label>
            <Form.Control
              type="password"
              name="newPassword"
              placeholder="Εισαγωγή νέου κωδικού πρόσβασης"
              value={formValues.newPassword}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="confirmNewPassword" className="mb-3">
            <Form.Label>Νέος κωδικός πρόσβασης</Form.Label>
            <Form.Control
              type="password"
              name="confirmNewPassword"
              placeholder="Επιβεβαίωση νέου κωδικού πρόσβασης"
              value={formValues.confirmNewPassword}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
        <Col md={3} className="p-5">
          <Button variant="outline-primary-bottom" type="submit" disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : "Αλλαγή κωδικού"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UserPassword;
