import React from "react";

/* Import UI components from react-bootstrap library */
import Accordion from 'react-bootstrap/Accordion';

const LawyerSpecial = ({ lawyer }) => {
  return (
    <div className="px-4 py-2">
      <h2 className="ld-h2 mb-4">Τομείς Εξειδίκευσης</h2>
      <Accordion defaultActiveKey="0">
        {lawyer.lawSpecialty.map((specialty, index) => {
          const matchingSubSpecialties = lawyer.lawSubSpecialty
            ? lawyer.lawSubSpecialty.filter(
                sub => sub.lawSpecialty.specialtyName === specialty.specialtyName
              )
            : [];

          return (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header>{specialty.specialtyName}</Accordion.Header>
              <Accordion.Body>
                {matchingSubSpecialties.length > 0 ? (
                  <ul>
                    {matchingSubSpecialties.map((sub, subIndex) => (
                      <li key={subIndex}>{sub.subSpecialtyName}</li>
                    ))}
                  </ul>
                ) : (
                  <p>Δεν υπάρχουν περεταίρω εξειδικεύσεις.</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
};

export default LawyerSpecial;
