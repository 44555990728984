import React, { useState } from "react";
import { Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../apiCalls/firebaseConfig";
import axios from "axios";
import './UserProfile.css';

const URL =
  "https://us-east-1-shared-usea1-02.cdn.hygraph.com/content/cm076afrs03tn07w4hrdwp8vl/master";
const AUTH_TOKEN =
  "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE3MjQ1MTkzMjYsImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN0LTEtc2hhcmVkLXVzZWExLTAyLmh5Z3JhcGguY29tL3YyL2NtMDc2YWZyczAzdG4wN3c0aHJkd3A4dmwvbWFzdGVyIiwibWFuYWdlbWVudC1uZXh0LmdyYXBoY21zLmNvbSJdLCJpc3MiOiJodHRwczovL21hbmFnZW1lbnQtdXMtZWFzdC0xLXNoYXJlZC11c2VhMS0wMi5oeWdyYXBoLmNvbS8iLCJzdWIiOiIzMThmNjIzMC1mOWU5LTRlMDEtYmE1Ny03NWNjNjMwMTFiOGYiLCJqdGkiOiJjbTA4ZWE0aTcwcXpuMDdrOWQ2M2E5cm1iIn0.yWBWyAIBEOZNEduBlI-6CgrUHnzAyij-3Bv7G_6gbPlLERdDW7usHO1AaZK8prIJ2PCTHQhAB9PQXGSmi5FRfTJpGCxXOaAwsfqrkrnBeG8i4bSi8K-BYNbDU2yAMXT-ZsZagNT_2jkyLXFEc3cgNqBtK50rWIAmcbSPxmQy8It-5SWK3q_SewYEVLcFZ7_3lN0G6k_P_u3mQvFcOsluTDGG24-TEeu6O4M0njK2gpLpBbyl8ZNZwYjEaLT7dMjXMzznc3Fo1CLW9WSZUFtMj9C2p4BLlIBioj8jZAxE-443GTSWDnCoUnh2J7ZIfHgGC8gpX6m5cmK2MMvfLXjSzFvZCqHFlbnF5CGj5-3WWmpVgZhzoO4h28cjx668mjKlRE1HwjVF8LYRLOjh1ZxjWxuMlsQd5rpLB3DrEuXTlvG_APZGOZdzZ2wwdNSe3AFMrhS-DtfT8H4GAFsb6UTamSeeiaJ3syNw0IwovP6mNsPLcIpcuzgbh-RU2OYQH83IsYL5e8N_KbX1ZLtIKHmCf40KnJGZafO9R-pUzqpoe1sAEeVt-7NATRC3Chc8ZYzQ1IUoUIF2__Wt-EDLapsaf3JwUutJFSpWI03VSy5jGBsiJvelmt-at8_cGm5_9eF6EllYwlIHJF0s7cSaQCr5ioAJeR9bcYkitGjRL62UXOA";


const UserInfo = ({ userInfo, lawyerInfo, setLawyerInfo }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleLawyerInfoChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setLawyerInfo({ ...lawyerInfo, [name]: newValue });
  };

  const handleSaveLawyerInfo = async () => {
    if (lawyerInfo) {
      setLoading(true);

      const mutation = `
        mutation UpdateLawyer($id: ID!, $data: LawyerUpdateInput!) {
          updateLawyer(where: { id: $id }, data: $data) {
            id
            firstName
            lastName
            lawGrade
            lawMediation
            lawProfileImage {
              url
              id
            }
            lawAssociation {
              associationName
              associationSlug
            }
          }
          publishLawyer(where: { id: $id }) {
            id
          }
        }
      `;

      const variables = {
        id: lawyerInfo.id,
        data: {
          firstName: lawyerInfo.firstName,
          lastName: lawyerInfo.lastName,
          lawGrade: lawyerInfo.lawGrade,
          lawMediation: lawyerInfo.lawMediation,
        },
      };

      try {
        const headers = {
          Authorization: `Bearer ${AUTH_TOKEN}`,
          "Content-Type": "application/json",
        };

        const response = await axios.post(
          URL,
          {
            query: mutation,
            variables,
          },
          { headers }
        );

        if (response.status === 200 && response.data.data) {
          setSuccessMessage("Lawyer information updated successfully.");
          setLawyerInfo({
            ...lawyerInfo,
            ...response.data.data.updateLawyer,
          });
        } else {
          setError("Failed to update lawyer information. Please try again.");
        }
      } catch (error) {
        setError("Error updating lawyer information. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
        setError("Failed to sign out.");
      });
  };

  const lawGradeOptions = [
    { value: "dikigoros_par_efetais", label: "Δικηγόρος παρ' Εφέταις" },
    { value: "dikigoros_para_protodikais", label: "Δικηγόρος παρά Πρωτοδίκας" },
    { value: "dikigoros_par_areio_pago", label: "Δικηγόρος παρ' Άρειω Πάγω" },
  ];

  return (
    <div className="user-info">
      {error && <Alert variant="danger">{error}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}

      <Form className="bottom-border-form px-5">
        <Row className="mb-3">
          <Col md={4}>
            <Form.Group controlId="firstName">
              <Form.Label>Όνομα</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={lawyerInfo.firstName}
                onChange={handleLawyerInfoChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="lastName">
              <Form.Label>Επώνυμο</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={lawyerInfo.lastName}
                onChange={handleLawyerInfoChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={userInfo.email}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="pt-4 mb-3">
          <Col md={4}>
            <Form.Group controlId="association">
              <Form.Label>Δικηγορικός Σύλλογος</Form.Label>
              <Form.Control
                type="text"
                name="association"
                value={lawyerInfo.lawAssociation?.associationName || ""}
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="grade">
              <Form.Label>Δικαίωμα Παράστασης</Form.Label>
              <Form.Control
                as="select"
                name="lawGrade"
                value={lawyerInfo.lawGrade}
                onChange={handleLawyerInfoChange}
              >
                {lawGradeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="mediation">
              <Form.Label>Διαμεσολάβητης</Form.Label>
              <Form.Check
                type="checkbox"
                className="py-4 mt-2"
                name="lawMediation"
                checked={lawyerInfo.lawMediation}
                onChange={handleLawyerInfoChange}
                label="Ναι, έχω πιστοποίηση"
              />
            </Form.Group>
          </Col>
        </Row>

        <Button
          variant="outline-primary-bottom"
          onClick={handleSaveLawyerInfo}
          className="me-2"
          disabled={loading}
        >
          {loading ? <Spinner animation="border" size="sm" /> : "Ενημέρωση"}
        </Button>
        <Button variant="outline-primary-bottom" onClick={handleSignOut}>
          Sign Out
        </Button>
      </Form>
    </div>
  );
};

export default UserInfo;
