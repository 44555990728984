import React from "react";

/* Import UI components from react-bootstrap library */
import { Container, Row } from "react-bootstrap";

/* Import Helmet component from react-helmet-async library */
import { Helmet } from "react-helmet-async";

/* Import .css to style the JSX template */
import "./Policy.css";

import privacyPolicyContent from "./policyPrivacyContent.json";

const SectionHeader = ({ children, level = 3 }) => {
  const Tag = `h${level}`;
  return <Tag className="py-3 fw-medium">{children}</Tag>;
};

function PolicyPrivacy() {
  return (
    <React.Fragment>
      <Helmet>
        <title>{privacyPolicyContent.title}  - upLawyer.gr | Βρες δικηγόρο εύκολα και απλά.</title>
      </Helmet>

      <Container className="my-3 pt-3">
        <Row className="text-center text-md-start">
          <h1 className="my-2">{privacyPolicyContent.title}</h1>
          <h5 className="mb-4 mt-3">{privacyPolicyContent.subtitle}</h5>
        </Row>
      </Container>

      <div className="px-4">
      <Container className="my-3">
        <Row className="policy-p">
          <p>{privacyPolicyContent.introduction}</p>

          {privacyPolicyContent.sections.map((section, index) => (
            <React.Fragment key={index}>
              <SectionHeader>{section.header}</SectionHeader>
              <p>{section.content}</p>
            </React.Fragment>
          ))}
        </Row>
      </Container>
        </div>
    </React.Fragment>
  );
}

export default PolicyPrivacy;
