import React from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col, Button } from "react-bootstrap";
import showdown from "showdown";

import "./LawyerBlog.css";

const converter = new showdown.Converter({
  noHeaderId: true,
  simplifiedAutoLink: true,
});

const LawyerBlogPosts = ({ lawBlogPost, gradeClass }) => (
  <>
    <Row className="px-4 pb-4">
      <h2 className="ld-h2 pt-3 pt-md-5">Αρθρογραφία</h2>
    </Row>
    <Row className="px-4">
      {lawBlogPost && lawBlogPost.length > 0 ? (
        lawBlogPost.map((post) => (
          <Col key={post.lawPostSlug} md={4} className="mb-4">
            <Card className={`blog-post-card blog-post-card-${gradeClass} h-100 p-4`}>
              <Card.Body>
                <Card.Title className="text-center">
                  <Link to={`/blog/${post.lawPostSlug}`} className="blog-post-lawyer-title">
                    {post.lawPostTitle}
                  </Link>
                </Card.Title>
                <Card.Text>
                  <div className="blog-post-excerpt my-4">
                    {converter
                      .makeHtml(post.lawPostBody.markdown.substring(0, 200))
                      .replace(/<[^>]+>/g, "")}
                    ...
                  </div>
                </Card.Text>
                <Button variant="outline-primary" as={Link} to={`/blog/${post.lawPostSlug}`}>
                  Πλήρες Άρθρο
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))
      ) : (
        <p>Δεν υπάρχουν δημοσιεύσεις ιστολογίου για αυτόν τον δικηγόρο.</p>
      )}
    </Row>
  </>
);

export default LawyerBlogPosts;
