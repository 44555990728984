import React from "react";

import { PiGraduationCapLight } from "react-icons/pi";

const LawyerStudies = ({ lawSchoolUnder, lawSchoolPost }) => {
  return (
    <div>
      {/* Undergraduate Studies Section */}
      {lawSchoolUnder && (
        <div className="d-flex align-items-center mb-4">
          <div className="icon-background me-4">
            <PiGraduationCapLight className="icon-style" />
          </div>
          <div>
            <h6 className="education-title mb-2">Προπτυχιακές Σπουδές</h6>
            <p className="education-subtitle mb-0">{lawSchoolUnder.lawSchoolName}</p>
          </div>
        </div>
      )}

      {/* Postgraduate Studies Section */}
      {lawSchoolPost && lawSchoolPost.length > 0 && (
        <div className="d-flex align-items-center mb-4">
          <div className="icon-background me-4">
            <PiGraduationCapLight className="icon-style" />
          </div>
          <div>
            <h6 className="education-title mb-2">Μεταπτυχιακές Σπουδές</h6>
            {lawSchoolPost.map((postgrad, index) => (
              <div key={index} className="mb-2">
                <p className="education-subtitle mb-0">{postgrad.lawSchoolName}</p>
                <p className="education-subtitle mb-0">{postgrad.lawSchoolTitle}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LawyerStudies;
