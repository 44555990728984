import React from "react";
import ReactCountryFlag from "react-country-flag";
import { PiGlobeSimpleLight } from "react-icons/pi";

const LawyerLang = ({ lawLanguage, languageToCountryCode }) => {
  return (
    <div className="d-flex align-items-center mb-2">
      <div className="icon-background me-4">
        <PiGlobeSimpleLight className="icon-style" />
      </div>
      <div>
        <h6 className="language-title mb-2">Ξένες Γλώσσες</h6>
        <div className="languages-list d-flex">
          {lawLanguage.map((language, index) => (
            <React.Fragment key={index}>
              <ReactCountryFlag
                countryCode={languageToCountryCode[language]}
                svg
                style={{
                  width: "1.5em",
                  height: "1.5em",
                }}
                title={language}
                className="lang-flag me-2"
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LawyerLang;
