import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

/* Import UI components from react-bootstrap library */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

/* Import fetchGraphQLData to facilitate the apiCalls to the Hygraph API */
import fetchGraphQLData from "../../apiCalls/fetchGraphQLData";

/* Import UI generic components  */
import LoadingSpinner from "../../components/ui-spinner";

/* Import UI generic components  */
import { FaRegEnvelopeOpen } from "react-icons/fa";

/* Import UI components from lawyer-components  */
import LawyerIntroDes from "./LawyerIntroDes";
import LawyerIntroMob from "./LawyerIntroMob";

import LawyerBio from "./LawyerBio";
import LawyerSpecial from "./LawyerSpecial";
import LawyerStudies from "./LawyerEdu";
import LawyerLanguages from "./LawyerLang";
import LawyerBlogPosts from "./LawyerBlog";
import LawyerContact from "./LawyerContact";
import LawyerDetailSEO from "./LawyerDetailSEO";
import LawyerAddress from "./LawyerDetailAddress";

import "./LawyerDetail.css";

// Import GSAP animation function
import { animateLawyerDetailPage } from "./LawyerDetailAnimations";

const languageToCountryCode = {
  english: "GB",
  french: "FR",
  german: "DE",
  spanish: "ES",
};

function LawyerDetailPage() {
  const { slugName } = useParams();
  const [lawyer, setLawyer] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const bioRef = useRef(null);
  const eduRef = useRef(null);
  const langRef = useRef(null);
  const blogRef = useRef(null);

  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  useEffect(() => {
    const fetchLawyerDetails = async () => {
      const query = `
      {
        lawyers(where: { slugName: "${slugName}" }) {
          id
          firstName
          lastName
          slugName
          lawSpecialty {
            specialtyName
          }
          lawSubSpecialty {
            subSpecialtyName
            lawSpecialty {
              specialtyName
            }
          }
          lawAssociation {
            associationName
            associationSlug
          }
          lawAddress {
            lawStreet
            lawCity
            lawRegion
            lawZipCode
            lawLat
            lawLon
          }
          lawContact {
            lawPhone
            lawPhoneType
          }
          lawGrade
          lawProfileImage {
            url
          }
          lawBlogPost {
            lawPostTitle
            lawPostBody {
              markdown
            }
            lawPostSlug
          }
          lawSchoolUnder {
            lawSchoolName
          }
          lawSchoolPost {
            lawSchoolName
            lawSchoolTitle
          }
          lawLanguage
          lawShortBio
          lawEmail
          lawMediation
        }
      }
      `;

      const data = await fetchGraphQLData(query);

      if (data && data.lawyers.length > 0) {
        setLawyer(data.lawyers[0]);
      }
    };

    fetchLawyerDetails();
  }, [slugName]);

  useEffect(() => {
    if (lawyer) {
      animateLawyerDetailPage(lawyer, {
        bioRef,
        eduRef,
        langRef,
        blogRef,
      });
    }
  }, [lawyer]);

  if (!lawyer) {
    return <LoadingSpinner />;
  }

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <LawyerDetailSEO lawyer={lawyer} slugName={slugName} />

      {isDesktop ? (
        <LawyerIntroDes lawyer={lawyer} />
      ) : (
        <LawyerIntroMob lawyer={lawyer} />
      )}

      <Container
        className={`${window.innerWidth >= 1068 ? "pt-5" : ""} my-4 ${
          showModal ? "blur-background" : ""
        }`}
      >
        {/* Section for lawyer specializations and address */}
        <Row className="pt-2">
          {/* Add section for lawyer specializations */}
          <Col md={8} sm={12}>
            <div ref={bioRef}>
              <LawyerBio lawShortBio={lawyer.lawShortBio} />
            </div>
          </Col>
          {/* Add section for lawyer address */}
          <Col md={4} sm={12} className="py-4 py-md-4 px-4">
            {/* Wrapper div to control mobile layout */}
            <div className="ld-bio-card-wrapper">
              <div className="ld-bio-card">
                <div className="ld-bio-card-inner px-4 py-4">
                  <LawyerStudies
                    lawSchoolUnder={lawyer.lawSchoolUnder}
                    lawSchoolPost={lawyer.lawSchoolPost}
                  />
                  <LawyerLanguages
                    lawLanguage={lawyer.lawLanguage}
                    languageToCountryCode={languageToCountryCode}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="align-center-row pt-2">
          <Col md={8} className="pt-1 pt-md-2">
            <LawyerSpecial lawyer={lawyer} />
          </Col>
          <Col md={4} sm={12} className="py-4 py-md-4 px-4">
          <h2 className="ld-h2 mb-4 px-3">Στοιχεία Επικοινωνίας</h2>
            <div className="ld-bio-card-wrapper">
              <div className="ld-bio-card">
                <div className="ld-bio-card-inner px-4 py-4">
                  <LawyerAddress lawyer={lawyer} />
                  <div className="mt-5">
              <Button
                className="btn btn-primary-contact w-100"
                onClick={handleShowModal}
              >
                Στείλε μήνυμα <FaRegEnvelopeOpen className="ms-2" />
              </Button>
            </div>
                </div>
              </div>
            </div>

            {/* Modal for Contact */}
            <LawyerContact
              show={showModal}
              handleClose={handleCloseModal}
              lawyer={lawyer}
            />
          </Col>
        </Row>

        {/* Section for lawyer blog posts */}
        <Row className="pt-2">
          <Col md={12} className="pt-1 pt-md-2">
            <div ref={blogRef}>
              <LawyerBlogPosts lawBlogPost={lawyer.lawBlogPost} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LawyerDetailPage;
