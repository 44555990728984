import React, { useState, useEffect } from "react";
import { Row, Col, Container, Spinner, Alert } from "react-bootstrap";

import { auth } from "../../apiCalls/firebaseConfig";
import fetchGraphQLData from "../../apiCalls/fetchGraphQLData";

import UserInfo from "./UserInfo";
import UserPassword from "./UserPassword";
import UserContact from "./UserContact";
import UserSocialMedia from "./UserSocialMedia";
import UserIntro from "./UserIntro";
import UserBio from "./UserBio";
import UserArticles from "./UserArticles";

import UserProfileImage from "./UserProfileImage";

import "./UserProfile.css";

const URL =
  "https://us-east-1-shared-usea1-02.cdn.hygraph.com/content/cm076afrs03tn07w4hrdwp8vl/master";
const AUTH_TOKEN =
  "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE3MjQ1MTkzMjYsImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN0LTEtc2hhcmVkLXVzZWExLTAyLmh5Z3JhcGguY29tL3YyL2NtMDc2YWZyczAzdG4wN3c0aHJkd3A4dmwvbWFzdGVyIiwibWFuYWdlbWVudC1uZXh0LmdyYXBoY21zLmNvbSJdLCJpc3MiOiJodHRwczovL21hbmFnZW1lbnQtdXMtZWFzdC0xLXNoYXJlZC11c2VhMS0wMi5oeWdyYXBoLmNvbS8iLCJzdWIiOiIzMThmNjIzMC1mOWU5LTRlMDEtYmE1Ny03NWNjNjMwMTFiOGYiLCJqdGkiOiJjbTA4ZWE0aTcwcXpuMDdrOWQ2M2E5cm1iIn0.yWBWyAIBEOZNEduBlI-6CgrUHnzAyij-3Bv7G_6gbPlLERdDW7usHO1AaZK8prIJ2PCTHQhAB9PQXGSmi5FRfTJpGCxXOaAwsfqrkrnBeG8i4bSi8K-BYNbDU2yAMXT-ZsZagNT_2jkyLXFEc3cgNqBtK50rWIAmcbSPxmQy8It-5SWK3q_SewYEVLcFZ7_3lN0G6k_P_u3mQvFcOsluTDGG24-TEeu6O4M0njK2gpLpBbyl8ZNZwYjEaLT7dMjXMzznc3Fo1CLW9WSZUFtMj9C2p4BLlIBioj8jZAxE-443GTSWDnCoUnh2J7ZIfHgGC8gpX6m5cmK2MMvfLXjSzFvZCqHFlbnF5CGj5-3WWmpVgZhzoO4h28cjx668mjKlRE1HwjVF8LYRLOjh1ZxjWxuMlsQd5rpLB3DrEuXTlvG_APZGOZdzZ2wwdNSe3AFMrhS-DtfT8H4GAFsb6UTamSeeiaJ3syNw0IwovP6mNsPLcIpcuzgbh-RU2OYQH83IsYL5e8N_KbX1ZLtIKHmCf40KnJGZafO9R-pUzqpoe1sAEeVt-7NATRC3Chc8ZYzQ1IUoUIF2__Wt-EDLapsaf3JwUutJFSpWI03VSy5jGBsiJvelmt-at8_cGm5_9eF6EllYwlIHJF0s7cSaQCr5ioAJeR9bcYkitGjRL62UXOA";

const ProfilePage = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [lawyerInfo, setLawyerInfo] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth.currentUser) {
      const currentUserEmail = auth.currentUser.email;
      setUserInfo({
        email: currentUserEmail,
        displayName: auth.currentUser.displayName,
        photoURL: auth.currentUser.photoURL,
      });

      const query = `
        {
          lawyers(where: { lawEmail: "${currentUserEmail}" }) {
            id
            firstName
            lastName
            slugName
            lawSpecialty {
              specialtyName
            }
            lawAssociation {
              associationName
              associationSlug
            }
            lawAddress {
              lawStreet
              lawCity
              lawRegion
              lawZipCode
            }
            lawContact {
              lawPhone
              lawPhoneType
            }
            lawSocialMedia {
              id
              lawWebsite
              lawLinkedin
              lawFacebook
            }
            lawGrade
            lawProfileImage {
              url
              id
            }
            lawBlogPost {
              id
              lawPostTitle
              lawPostBody {
                markdown
              }
            lawPostSlug
            }
            lawMediation
            lawShortBio
          }
        }`;

      fetchGraphQLData(query)
        .then((data) => {
          if (data && data.lawyers && data.lawyers.length > 0) {
            setLawyerInfo(data.lawyers[0]);
          } else {
            setError("No lawyer data found for the current user.");
          }
        })
        .catch((error) => {
          console.error("Error fetching lawyer data:", error);
          setError("Error fetching lawyer data.");
        })
        .finally(() => {
          setLoading(false); // Stop loading spinner after the fetch
        });
    } else {
      setError("User not authenticated.");
      setLoading(false);
    }
  }, []);

  return (
    <>
      {lawyerInfo && <UserIntro lawyerInfo={lawyerInfo} />}
  
      <Container className="my-4">
        {error && <Alert variant="danger">{error}</Alert>}
        {loading && <Spinner animation="border" />}
        {!loading && userInfo && lawyerInfo && (
          <>
            <Row>
              <Col
                md={2}
                className="d-flex justify-content-center align-items-center"
              >
                <h4 className="my-4 pb-4">Προσωπικές Πληροφορίες</h4>
              </Col>
              <Col md={10}>
                <UserInfo
                  userInfo={userInfo}
                  lawyerInfo={lawyerInfo}
                  setLawyerInfo={setLawyerInfo}
                />
              </Col>
            </Row>
  
            <Row>
              <Col
                md={2}
                className="d-flex justify-content-center align-items-center"
              >
                <h4 className="my-4 pb-4 lh-lg">Στοιχεία Επικοινωνίας</h4>
              </Col>
              <Col md={10}>
                <UserContact
                  lawyerInfo={lawyerInfo}
                  AUTH_TOKEN={AUTH_TOKEN}
                  URL={URL}
                  setLawyerInfo={setLawyerInfo}
                />
              </Col>
            </Row>
  
            <UserPassword />
  
            <UserSocialMedia
              lawyerInfo={lawyerInfo}
              AUTH_TOKEN={AUTH_TOKEN}
              URL={URL}
              setLawyerInfo={setLawyerInfo}
            />
  
            <UserBio
              lawyerInfo={lawyerInfo}
              AUTH_TOKEN={AUTH_TOKEN}
              URL={URL}
              setLawyerInfo={setLawyerInfo}
            />
  
            <UserArticles
              lawyerInfo={lawyerInfo}
              AUTH_TOKEN={AUTH_TOKEN}
              URL={URL}
              setLawyerInfo={setLawyerInfo}
            />
  
            <UserProfileImage
              lawyerInfo={lawyerInfo}
              AUTH_TOKEN={AUTH_TOKEN}
              URL={URL}
              setLawyerInfo={setLawyerInfo}
            />
          </>
        )}
      </Container>
    </>
  );
  
};

export default ProfilePage;
