import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

/* Import fetchGraphQLData to facilitate the apiCalls to the Hygraph API */
import fetchGraphQLData from "../../apiCalls/fetchGraphQLData";

/* Import UI components from react-bootstrap library */
import { Container, Row, Col } from "react-bootstrap";

/* Import the reusable BlogPost component */
import BlogPost from "./LawBlogCard";

function LawBlogPosts() {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      const blogPostsQuery = `
      {
        lawBlogPosts(first: 10) {
          id
          lawPostTitle
          lawPostDate
          lawPostSlug
          lawPostBody {
            markdown
          }
          lawyer {
            firstName
            lastName
          }
        }
      }
      `;

      try {
        const { lawBlogPosts } = (await fetchGraphQLData(blogPostsQuery)) ?? {};

        if (lawBlogPosts) {
          setBlogPosts(lawBlogPosts);
        } else {
          throw new Error(
            `Unexpected data structure: ${JSON.stringify(lawBlogPosts)}`
          );
        }
      } catch (error) {
        console.error("Error making API request:", error);
      }
    };

    fetchBlogPosts();
  }, []);

  return (
    <>
      <Container className="my-5 text-center">
        <Row>
          <Col>
            <h1>Αρθρογραφία</h1>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <h2 className="h5 lh-lg">
              Επιλεγμένη αρθρογραφία και νομικά κείμενα από δικηγόρους του{" "}
              <span className="navbar-brand">upLawyer</span>.
            </h2>
          </Col>
        </Row>
      </Container>

      <Container className=" my-5 px-4">
        <Row className="g-5">
          {blogPosts.map((post) => (
            <Col key={post.id} sm={12} md={6} lg={6}>
              <Link
                to={`/blog/${post.lawPostSlug}`}
                className="text-decoration-none"
              >
                <BlogPost post={post} />
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default LawBlogPosts;
