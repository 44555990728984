import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import emailjs from 'emailjs-com';
import validator from 'validator';

function LawyerContact({ show, handleClose, lawyer }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isEmailTouched, setIsEmailTouched] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleEmailBlur = () => {
    if (!validator.isEmail(email)) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
    setIsEmailTouched(true);
  };

  useEffect(() => {
    if (firstName && lastName && email && message && isEmailValid && isChecked) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [firstName, lastName, email, message, isEmailValid, isChecked]);

  const sendEmail = (e) => {
    e.preventDefault();

    if (!isEmailValid) {
      alert('Please enter a valid email address.');
      return;
    }

    const templateParams = {
      user_email: email,
      message: message,
      from_name: `${firstName} ${lastName}`,
      to_email: lawyer.lawEmail,
      to_name: `${lawyer.firstName} ${lawyer.lastName}`,
      reply_to: email
    };

    emailjs.send('service_eowssfo', 'template_tmgliwr', templateParams, 'tYhu1RNA5gZE5MZmi')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Email sent successfully!');
        handleClose();
      }, (error) => {
        console.log('FAILED...', error);
        alert('Failed to send email, please try again.');
      });
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Επικοινωνία με <span className="navbar-brand fw-medium">{lawyer.firstName} {lawyer.lastName}</span></Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-2 mt-2">
        <Form onSubmit={sendEmail}>
          
          {/* First and Last name inputs */}
          <Row>
            <Col xs={12} md={6} className="mb-3 mb-md-0">
              <Form.Group controlId="formFirstName">
                <Form.Label>Όνομα</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Εισαγωγή ονόματος"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formLastName">
                <Form.Label>Επώνυμο</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Εισαγωγή επώνυμου"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>


          {/* Email input */}
          <Form.Group controlId="formBasicEmail" className="mt-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Εισαγωγή email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={handleEmailBlur}
              isInvalid={!isEmailValid && isEmailTouched}
              required
            />
            <Form.Control.Feedback type="invalid">
              Παρακαλώ εισάγετε μία έγκυρη διεύθυνση email.
            </Form.Control.Feedback>
          </Form.Group>

          {/* Message input */}
          <Form.Group controlId="formMessage" className="mt-3">
            <Form.Label>Μήνυμα</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Εισαγωγή μίας σύντομης περιγραφής του μηνύματος."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              style={{ resize: 'none' }}
            />
          </Form.Group>

          {/* Terms and Conditions Checkbox */}
          <Form.Group controlId="formTerms" className="mt-3">
            <Form.Check 
              type="checkbox" 
              label="Αποδέχομαι όρους & πολιτική απορρήτου" 
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
              required 
            />
          </Form.Group>

          <Modal.Footer>
            <Button 
              className="mt-3 w-100" 
              variant="outline-primary" 
              type="submit" 
              disabled={!isFormValid}
            >
              Αποστολή
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default LawyerContact;
