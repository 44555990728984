import React from "react";
import { Form, Row, Col, ListGroup, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const UserArticles = ({ lawyerInfo }) => {
  const navigate = useNavigate();

  const handleEdit = (post) => {
    // Navigate to the edit page with article details as route parameters
    navigate(`/edit-article/${post.id}`, {
      state: {
        title: post.lawPostTitle,
        body: post.lawPostBody.markdown,
        slug: post.lawPostSlug,
        id: post.id,
      },
    });
  };

  return (
    <Form className="bottom-border-form px-5" noValidate>
      <h4 className="my-4 pb-4">Άρθρα</h4>

      {/* Display Blog Posts with Edit and Remove buttons */}
      <Row className="mb-3">
        <Col md={12}>
          {lawyerInfo.lawBlogPost && lawyerInfo.lawBlogPost.length > 0 ? (
            <ListGroup>
              {lawyerInfo.lawBlogPost.map((post, index) => (
                <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
                  <span>{post.lawPostTitle}</span>
                  <div className="d-inline-flex">
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="me-2"
                      onClick={() => handleEdit(post)}
                    >
                      Edit
                    </Button>
                    <Button variant="outline-danger" size="sm">
                      Remove
                    </Button>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <p>Δεν υπάρχουν διαθέσιμα άρθρα.</p>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default UserArticles;
