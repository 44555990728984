import React from "react";

const LawyerBio = ({ lawShortBio }) => (
  <div className="mb-4 px-4 py-2">
    <h2 className="ld-h2 mb-3">Βιογραφικό</h2>
    <div className="bio-content text-md lh-lg">
      {lawShortBio}
    </div>
  </div>
);

export default LawyerBio;
